<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground pb-5 ">
          

          <div class="row pt-5  justify-content-center">
            <div class="col-md-5 title grauHellfeld">
              <h1
                class="rotSgrauBig fw-bolder"
                style="text-align: center"
              >
                Impressum
              </h1>
              <h4 pt-5>
                HERTWIG CNC Präzisions - Fertigungstechnik GmbH<br />
                Königseer Straße 51<br />
                07422 Bad Blankenburg<br />
              </h4>
              <h4 class="pt-5">
                Telefon: 036741 18916-0<br />Fax: 036741 18916-9
              </h4>
              <h4>
                E-Mail:
                <a href="mailto:info@hertwig-cnc.de" class="email"
                  >info@hertwig-cnc.de</a
                >
              </h4>
            </div>
          </div>

          <div class="row justify-content-center pt-5">
            <div class="col-md-7 bgWeiss15">
              
            </div>
          </div>

          <div class="row pt-3 mb-0 mt-0 justify-content-center">
            <div class="col-md-10 title grauHellfeld">
              <h1 class="rotSgrauBig fw-bolder " style="text-align: center">
                Allgemeine Lieferbedingungen (ALB)
              </h1>
              <h3 class="rotSgrau text-center"> 
                der HERTWIG CNC Präzisions-Fertigungstechnik GmbH
              </h3>
              <h5 class="rotSgrau text-center"> 
                Stand 01/2015
              </h5>
              <h5 class="rotSgrau text-center pt-5 pb-5" >                 
                  <a href="http://www.hertwig-cnc.de/downloads/allgemeine-lieferbedingungen---k94571---_id-17.pdf" target="_blank">Bitte laden sie HIER unsere ALB herunter  </a>
              </h5>
              <h4 pt-5>1. Geltung</h4>
              <p>
                1.1. <br />Alle Lieferungen, Leistungen und Angebote der HERTWIG
                CNC PräzisionsFertigungstechnik GmbH (im Folgenden „Verkäufer“
                genannt) erfolgen ausschließlich unter Einbeziehung dieser ALB.
                Diese sind Bestandteil aller Verträge, die der Verkäufer mit
                seinen Vertragspartnern (nachfolgend auch „Käufer“ genannt) über
                die von ihm angebotenen Lieferungen und Leistungen schließt.<br /><br />
                1.2. <br />Geschäftsbedingungen des Käufers oder Dritter finden
                keine Anwendung, auch wenn der Verkäufer ihrer Geltung im
                Einzelfall nicht gesondert widerspricht, es sei denn, der
                Verkäufer stimmt ihrer Geltung ausdrücklich und schriftlich zu.
                Selbst wenn der Verkäufer auf ein Schreiben Bezug nimmt, das
                Geschäftsbedingungen des Käufers oder eines Dritten enthält oder
                auf solche verweist, liegt darin kein Einverständnis mit der
                Geltung jener Geschäftsbedingungen. <br /><br />1.3. <br />Sie
                gelten auch für alle zukünftigen Lieferungen, Leistungen oder
                Angebote an den Käufer, selbst wenn sie nicht noch einmal
                gesondert vereinbart werden.
              </p>
              <h4>2. Vertragsabschluss</h4>
              <p>
                2.1.  <br />Alle Angebote des Verkäufers sind freibleibend und
                unverbindlich, sofern sie nicht ausdrücklich als verbindlich
                gekennzeichnet sind oder eine bestimmte Annahmefrist enthalten. <br /> <br />
                2.2.  <br />Angaben des Verkäufers zum Gegenstand der Leistung sowie
                die Darstellungen desselben (z. B. Zeichnungen oder Abbildungen)
                sind nur annähernd maßgeblich, soweit nicht die Verwendbarkeit
                zum vertraglich vorgesehenen Zweck eine genaue Übereinstimmung
                voraussetzt. Sie sind keine garantierten
                Beschaffenheitsmerkmale, sondern Beschreibungen oder
                Kennzeichnungen der Lieferung oder Leistung.  <br /> <br />2.3.  <br />Weicht der vom
                Käufer erteilte Auftrag von Angebot des Verkäufers ab, so wird
                der Käufer die Abweichungen gesondert kenntlich machen. <br /> <br /> 2.4. <br />
                Bestellungen oder Aufträge kann der Verkäufer innerhalb von
                vierzehn Tagen nach Zugang annehmen.  <br /> <br />2.5.  <br />Der Verkäufer behält
                sich vor, die Bearbeitung der Lieferungs- oder
                Leistungsgegenstände ohne Mehrkosten für den Käufer in einem
                anderen Betrieb durchzuführen oder durchführen zu lassen.
              </p>
              <h4>3. Auftragsänderungen</h4>
              <p>
                3.1.  <br />Ergänzungen oder Abänderungen der getroffenen
                Vereinbarungen einschließlich dieser ALB bedürfen zur ihrer
                Wirksamkeit der Zustimmung aller Vertragsparteien und der
                Schriftform. Dies gilt auch für die Abänderung dieser
                Formvorschriften.  <br /> <br />3.2.  <br />Der Verkäufer behält sich bei fehlenden
                oder fehlerhaften Informationen vor, den Lieferungs- oder
                Leistungsgegenstand angemessen zu ändern bzw. anzupassen.  <br /> <br />3.3. <br />
                Technische Änderungen des Lieferungs- oder
                Leistungsgegenstandes, die das Vertragsziel nicht gefährden,
                bleiben vorbehalten.
              </p>
              <h4>4. Lieferzeit</h4>
              <p>
                4.1.  <br />Vom Verkäufer in Aussicht gestellte Fristen und Termine für
                Lieferungen und Leistungen gelten stets nur annähernd, es sei
                denn, dass ausdrücklich eine feste Frist oder ein fester Termin
                zugesagt oder vereinbart ist.  <br /> <br />4.2.  <br />Ist eine Lieferungs- oder
                Leistungsfrist vereinbart, so beginnt diese mit der Absendung
                der Auftragsbestätigung, jedoch nicht vor vollständiger
                Klarstellung aller Einzelheiten des Auftrags sowie der
                ordnungsgemäßen Erfüllung aller Mitwirkungspflichten des
                Käufers; entsprechendes gilt für Liefer- und Leistungstermine.
                 <br /> <br />4.3.  <br />Die Liefer- und Leistungszeit ist eingehalten, wenn bis zu
                ihrem Ablauf der Lieferoder Leistungsgegenstand das Werk des
                Verkäufers verlassen hat oder der Verkäufer die Fertigstellung
                zur Abholung angezeigt hat.  <br /> <br />4.4.  <br />Bei einvernehmlicher Änderung
                des Auftragsgegenstandes sind Liefer- und Leistungsfristen und
                Liefer- und Leistungstermine neu zu vereinbaren. Dies gilt auch
                dann, wenn über den Auftragsgegenstand nach Vertragsschluss
                erneut verhandelt wurde, ohne dass eine Änderung des
                Auftragsgegenstandes vorgenommen wurde.  <br /> <br />4.5.  <br />Liefer- und
                Leistungsfristen und Liefer- und Leistungstermine stehen unter
                dem Vorbehalt der mangelfreien und rechtzeitigen Vorlieferung
                sowie unvorhersehbarer Produktionsstörungen.  <br /> <br />4.6.  <br />Der Verkäufer
                ist berechtigt, bereits vor vereinbarter Zeit die vereinbarte
                Lieferung oder Leistung zu erbringen.  <br /> <br />4.7.  <br />Der Verkäufer ist nur
                zu Teillieferungen berechtigt, wenn − die Teillieferung für den
                Käufer im Rahmen des vertraglichen Bestimmungszwecks verwendbar
                ist, − die Lieferung der restlichen bestellten Ware
                sichergestellt ist und − dem Käufer hierdurch kein erheblicher
                Mehraufwand oder zusätzliche Kosten entstehen (es sei denn, der
                Verkäufer erklärt sich zur Übernahme dieser Kosten bereit).  <br /> <br />4.8. <br />
                Lagerkosten nach Gefahrübergang trägt der Käufer. Bei Lagerung
                durch den Verkäufer betragen die Lagerkosten 0,25 % des
                Rechnungsbetrages der zu lagernden Liefergegenstände pro
                abgelaufene Woche. Die Geltendmachung und der Nachweis weiterer
                oder geringerer Lagerkosten bleiben vorbehalten.
              </p>
              <h4>5. Höhere Gewalt</h4>
              <p>
                5.1. <br />Der Verkäufer haftet nicht für Unmöglichkeit der Lieferung
                oder für Lieferverzögerungen, soweit diese durch höhere Gewalt
                oder sonstige, zum Zeitpunkt des Vertragsabschlusses nicht
                vorhersehbare Ereignisse (z. B. Betriebsstörungen aller Art,
                Schwierigkeiten in der Material- oder Energiebeschaffung,
                Transportverzögerungen, Streiks, rechtmäßige Aussperrungen,
                Mangel an Arbeitskräften, Energie oder Rohstoffen,
                Schwierigkeiten bei der Beschaffung von notwendigen behördlichen
                Genehmigungen, behördliche Maßnahmen oder die ausbleibende,
                nicht richtige oder nicht rechtzeitige Belieferung durch
                Lieferanten) verursacht worden sind, die der Verkäufer nicht zu
                vertreten hat.  <br /> <br />5.2.  <br />Bei Hindernissen vorübergehender Dauer
                verlängern sich die Liefer- oder Leistungsfristen oder
                verschieben sich die Liefer- oder Leistungstermine um den
                Zeitraum der Behinderung zuzüglich einer angemessenen
                Anlauffrist.  <br /> <br />5.3.  <br />Sofern solche Ereignisse dem Verkäufer die
                Lieferung oder Leistung wesentlich erschweren oder unmöglich
                machen und die Behinderung nicht nur von vorübergehender Dauer
                ist, ist der Verkäufer zum Rücktritt vom Vertrag berechtigt.
              </p>
              <h4>6. Zahlungen</h4>
              <p>
                6.1.  <br />Die Preise gelten für den in den Auftragsbestätigungen
                aufgeführten Leistungs- und Lieferungsumfang. Mehr- oder
                Sonderleistungen werden gesondert berechnet. Die Preise
                verstehen sich in Euro ab Werk zuzüglich Verpackung, der
                gesetzlichen Mehrwertsteuer, bei Exportlieferungen Zoll sowie
                Gebühren und anderer öffentlicher Abgaben.  <br /> <br />6.2.  <br />Die Sendung wird
                vom Verkäufer nur auf ausdrücklichen Wunsch des Käufers und auf
                seine Kosten gegen Diebstahl, Bruch-, Transport-, Feuer- und
                Wasserschäden oder sonstige versicherbare Risiken versichert.
                 <br /> <br />6.3.  <br />Der Verkäufer ist berechtigt, den vereinbarten Preis
                angemessen zu ändern, wenn nach Abschluss des Vertrages
                Kostenerhöhungen, insbesondere aufgrund von Tarifverträgen,
                Material- oder Energiepreisänderungen, eintreten.  <br /> <br />6.4. <br />
                Rechnungsbeträge sind innerhalb von 30 Tagen ohne jeden Abzug zu
                bezahlen, sofern nicht etwas anderes schriftlich vereinbart ist.
                Maßgebend für das Datum der Zahlung ist der Eingang beim
                Verkäufer. Schecks gelten erst nach Einlösung als Zahlung.  <br /> <br />6.5. <br />
                Der Verkäufer ist berechtigt, noch ausstehende Lieferungen oder
                Leistungen nur gegen Vorauszahlung oder Sicherheitsleistung
                auszuführen oder zu erbringen, wenn ihm nach Abschluss des
                Vertrages Umstände bekannt werden, welche die Kreditwürdigkeit
                des Käufers wesentlich zu mindern geeignet sind und durch welche
                die Bezahlung der offenen Forderungen des Verkäufers durch den
                Käufer aus dem jeweiligen Vertragsverhältnis gefährdet wird.
                 <br /> <br /> <br />6.6. Die Aufrechnung mit Gegenansprüchen des Käufers oder die
                Zurückbehaltung von Zahlungen wegen solcher Ansprüche ist nur
                zulässig, soweit die Gegenansprüche unbestritten oder
                rechtskräftig festgestellt sind.
              </p>
              <h4>7. Erfüllungsort, Abnahme, Gefahrübergang, Verpackung</h4>
              <p>
                7.1.  <br />Erfüllungsort für alle Verpflichtungen aus dem
                Vertragsverhältnis ist die Hermann – Petersilge - Str.3, 07422
                Bad Blankenburg soweit nichts anderes bestimmt ist. Schuldet der
                Verkäufer auch die Installation, ist Erfüllungsort der Ort, an
                dem die Installation zu erfolgen hat.  <br /> <br />7.2.  <br />Soweit eine Abnahme
                stattzufinden hat, gilt die Kaufsache als abgenommen, wenn − die
                Lieferung und, sofern der Verkäufer auch die Installation
                schuldet, die Installation abgeschlossen ist, − seit der
                Lieferung oder Installation 12 Werktage vergangen sind oder der
                Käufer mit der Nutzung der Kaufsache begonnen hat (z. B. die
                gelieferte Anlage in Betrieb genommen hat) und in diesem Fall
                seit Lieferung oder Installation sechs Werktage vergangen sind
                und − der Käufer die Abnahme innerhalb dieses Zeitraums aus
                einem anderen Grund als wegen eines dem Verkäufer angezeigten
                Mangels, der die Nutzung der Kaufsache unmöglich macht oder
                wesentlich beeinträchtigt, unterlassen hat. <br /> <br /> 7.3.  <br />Die Gefahr geht
                spätestens mit der Übergabe des Liefergegenstandes an den
                Spediteur, Frachtführer oder sonst zur Ausführung der Versendung
                bestimmten Dritten auf den Käufer über. Dies gilt auch dann,
                wenn Teillieferungen erfolgen oder der Verkäufer noch andere
                Leistungen (z. B. Versand oder Installation) übernommen hat.
                 <br /> <br />7.4.  <br />Verzögert sich der Versand oder die Übergabe infolge eines
                Umstandes, dessen Ursache beim Käufer liegt, geht die Gefahr von
                dem Tag an auf den Käufer über, an dem der Liefergegenstand
                versandbereit ist und der Verkäufer dies dem Käufer angezeigt
                hat.  <br /> <br />7.5.  <br />Erfolgt der Versand in Leihverpackungen, sind diese
                innerhalb von 30 Tagen nach Erhalt der Lieferung frachtfrei
                zurückzusenden. Verlust und Beschädigung der Leihverpackungen
                hat der Käufer zu vertreten.  <br /> <br />7.6.  <br />Leihverpackungen dürfen nicht
                zu anderen Zwecken oder zur Aufnahme anderer Gegenstände dienen.
                Sie sind lediglich für den Transport der gelieferten Ware
                bestimmt. Beschriftungen dürfen nicht entfernt werden.  <br /> <br />7.7.  <br />Die
                Wahl der Versandart und die Verpackung stehen im pflichtgemäßen
                Ermessen des Verkäufers.
              </p>
              <h4>8. Untersuchungs- und Rügeobliegenheiten</h4>
              <p>
                8.1. Die gelieferten Gegenstände sind unverzüglich nach
                Ablieferung an den Käufer oder an den von ihm bestimmten Dritten
                sorgfältig zu untersuchen.  <br /> <br />8.2.  <br />Auf Verlangen des Verkäufers ist
                ein beanstandeter Liefergegenstand frachtfrei an den Verkäufer
                zurückzusenden.  <br /> <br />8.3.  <br />Konnte ein Mangel bei Wareneingang nicht
                entdeckt werden, ist bei späterer Entdeckung eine etwaigen
                Weiterverarbeitung des Liefer- oder Leistungsgegenstandes
                unverzüglich einzustellen.
              </p>
              <h4>9. Gewährleistung</h4>
              <p>
                9.1.  <br />Bei Sachmängeln der gelieferten Gegenstände ist der
                Verkäufer nach seiner innerhalb angemessener Frist zu treffenden
                Wahl zunächst zur Nachbesserung oder Ersatzlieferung
                verpflichtet und berechtigt.  <br /> <br />9.2.  <br />Bei Mängeln von Bauteilen
                anderer Hersteller, die der Verkäufer aus lizenzrechtlichen oder
                tatsächlichen Gründen nicht beseitigen kann, wird der Verkäufer
                nach seiner Wahl seine Gewährleistungsansprüche gegen die
                Hersteller und Lieferanten für Rechnung des Käufers geltend
                machen oder an den Käufer abtreten.  <br /> <br />9.3.  <br />Die Gewährleistung
                entfällt, wenn der Käufer ohne Zustimmung des Verkäufers den
                Liefergegenstand ändert oder durch Dritte ändern lässt und die
                Mängelbeseitigung hierdurch unmöglich oder unzumutbar erschwert
                wird. In jedem Fall hat der Käufer die durch die Änderung
                entstehenden Mehrkosten der Mängelbeseitigung zu tragen.
              </p>
              <h4>10. Rechtsmängel, Schutzrechte</h4>
              <p>
                10.1.  <br />Aufträge nach dem Verkäufer übergebenen Zeichnungen,
                Skizzen oder sonstigen Angaben werden auf Gefahr des Käufers
                ausgeführt. Wenn der Verkäufer infolge der Ausführung solcher
                Bestellungen in fremde Schutzrechte eingreift, stellt der Käufer
                den Verkäufer von den Ansprüchen dieser Rechtsinhaber frei.
                Weitergehende Schäden trägt der Käufer.  <br /> <br />10.2.  <br />Die Haftung des
                Verkäufers für die Verletzung von fremden Schutzrechten
                erstreckt sich nur auf solche Schutzrechte, welche in
                Deutschland registriert und veröffentlicht sind.
              </p>
              <h4>11. Haftung</h4>
              <p>
                11.1.  <br />Die Haftung des Verkäufers auf Schadensersatz, gleich aus
                welchem Rechtsgrund, insbesondere aus Unmöglichkeit, Verzug,
                mangelhafter oder falscher Lieferung, Vertragsverletzung,
                Verletzung von Pflichten bei Vertragsverhandlungen und
                unerlaubter Handlung ist, soweit es dabei jeweils auf ein
                Verschulden ankommt, nach Maßgabe dieser Ziffer 11
                eingeschränkt.  <br /> <br />11.2.  <br />Der Verkäufer haftet nicht im Falle
                einfacher Fahrlässigkeit seiner Organe, gesetzlichen Vertreter,
                Angestellten oder sonstigen Erfüllungsgehilfen, soweit es sich
                nicht um eine Verletzung vertragswesentlicher Pflichten handelt.
                 <br /> <br />11.3.  <br />Soweit der Verkäufer dem Grunde nach auf Schadensersatz
                haftet, ist diese Haftung auf Schäden begrenzt, die der
                Verkäufer bei Vertragsschluss als mögliche Folge einer
                Vertragsverletzung vorausgesehen hat oder die er bei Anwendung
                verkehrsüblicher Sorgfalt hätte voraussehen müssen.  <br /> <br />11.4. <br />
                Mittelbare Schäden und Folgeschäden, die Folge von Mängeln des
                Liefergegenstands sind, sind außerdem nur ersatzfähig, soweit
                solche Schäden bei bestimmungsgemäßer Verwendung des
                Liefergegenstands typischerweise zu erwarten sind.  <br /> <br />11.5.  <br />Im
                Falle einer Haftung für einfache Fahrlässigkeit ist die
                Ersatzpflicht des Verkäufers für Sachschäden und daraus
                resultierende weitere Vermögensschäden auf einen Betrag von 2,5
                Millionen EUR je Schadensfall beschränkt, auch wenn es sich um
                eine Verletzung vertragswesentlicher Pflichten handelt.  <br /> <br />11.6. <br />
                Die Einschränkungen dieser Ziffer11 gelten nicht für die Haftung
                des Verkäufers wegen vorsätzlichen Verhaltens, für garantierte
                Beschaffenheitsmerkmale, wegen Verletzung des Lebens, des
                Körpers oder der Gesundheit oder nach dem Produkthaftungsgesetz.
                 <br /> <br />11.7.  <br />Die Haftung des Verkäufers ist ausgeschlossen, soweit der
                Käufer seinerseits die Haftung gegenüber seinem Abnehmer wirksam
                beschränkt hat.  <br /> <br />11.8.  <br />Soweit der Verkäufer technische Auskünfte
                gibt oder beratend tätig wird und diese Auskünfte oder Beratung
                nicht zu dem von ihm geschuldeten, vertraglich vereinbarten
                Leistungsumfang gehören, geschieht dies unentgeltlich und unter
                Ausschluss jeglicher Haftung.
              </p>
              <h4>12. Verjährung</h4>
              <p>
                12.1.  <br />Die Gewährleistungsfrist beträgt ein Jahr ab Lieferung
                oder, soweit eine Abnahme erforderlich ist, ab der Abnahme.
                 <br /> <br />12.2.  <br />Nacherfüllungsmaßnahmen hemmen weder die für die
                ursprüngliche Leistungserbringung geltende Verjährungsfrist,
                noch lassen sie die Verjährung neu beginnen.  <br /> <br />12.3. <br />
                Eigentumserwerb, -vorbehalt, Pfandrecht  <br /> <br />12.4. <br /> Der nachfolgend
                vereinbarte Eigentumsvorbehalt dient der Sicherung aller jeweils
                bestehenden derzeitigen und künftigen Forderungen des Verkäufers
                gegen den Käufer aus der zwischen den Vertragspartnern
                bestehenden Lieferbeziehung (einschließlich Saldoforderungen aus
                einem auf diese Lieferbeziehung beschränkten
                Kontokorrentverhältnis).  <br /> <br />12.5.  <br />Der Verkäufer behält sich das
                Eigentum an allen Vertragsgegenständen bis zum vollständigen
                Ausgleich aller aus der Geschäftsverbindung mit dem Käufer
                zustehenden Forderungen vor. Die Ware sowie die nach den
                nachfolgenden Bestimmungen an ihre Stelle tretende, vom
                Eigentumsvorbehalt erfasste, Ware wird nachfolgend
                „Vorbehaltsware“ genannt. <br /> <br /> 12.6.  <br />Der Käufer verwahrt die
                Vorbehaltsware unentgeltlich für den Verkäufer.  <br /> <br />12.7.  <br />Der Käufer
                ist berechtigt, die Vorbehaltsware bis zum Eintritt des
                Verwertungsfalls im ordnungsgemäßen Geschäftsverkehr zu
                verarbeiten und zu veräußern. Verpfändungen und
                Sicherungsübereignungen sind unzulässig.  <br /> <br />12.8.  <br />Wird die
                Vorbehaltsware vom Käufer verarbeitet, so wird vereinbart, dass
                die Verarbeitung im Namen und für Rechnung des Verkäufers als
                Hersteller erfolgt und der Verkäufer unmittelbar das Eigentum
                oder – wenn die Verarbeitung aus Stoffen mehrerer Eigentümer
                erfolgt oder der Wert der verarbeiteten Sache höher ist als der
                Wert der Vorbehaltsware – das Miteigentum (Bruchteilseigentum)
                an der neu geschaffenen Sache im Verhältnis des Werts der
                Vorbehaltsware zum Wert der neu geschaffenen Sache erwirbt. Für
                den Fall, dass kein solcher Eigentumserwerb beim Verkäufer
                eintreten sollte, überträgt der Käufer bereits jetzt sein
                künftiges Eigentum oder – im o. g. Verhältnis – Miteigentum an
                der neu geschaffenen Sache zur Sicherheit an den Verkäufer. Wird
                die Vorbehaltsware mit anderen Sachen zu einer einheitlichen
                Sache verbunden oder untrennbar vermischt und ist eine der
                anderen Sachen als Hauptsache anzusehen, so überträgt der
                Verkäufer, soweit die Hauptsache ihm gehört, dem Käufer anteilig
                das Miteigentum an der einheitlichen Sache in dem in Satz 1
                genannten Verhältnis.  <br /> <br />12.9.  <br />Im Fall der Weiterveräußerung der
                Vorbehaltsware tritt der Käufer bereits jetzt sicherungshalber
                die hieraus entstehende Forderung gegen den Erwerber – bei
                Miteigentum des Verkäufers an der Vorbehaltsware anteilig
                entsprechend dem Miteigentumsanteil – an den Verkäufer ab.
                Gleiches gilt für sonstige Forderungen, die an die Stelle der
                Vorbehaltsware treten oder sonst hinsichtlich der Vorbehaltsware
                entstehen, wie z. B. Versicherungsansprüche oder Ansprüche aus
                unerlaubter Handlung bei Verlust oder Zerstörung. Der Verkäufer
                ermächtigt den Käufer widerruflich, die an den Verkäufer
                abgetretenen Forderungen im eigenen Namen einzuziehen. Der
                Verkäufer darf diese Einzugsermächtigung nur im Verwertungsfall
                widerrufen.  <br /> <br />12.10. <br /> Greifen Dritte auf die Vorbehaltsware zu,
                insbesondere durch Pfändung, wird der Käufer sie unverzüglich
                auf das Eigentum des Verkäufers hinweisen und den Verkäufer
                hierüber informieren, um ihm die Durchsetzung seiner
                Eigentumsrechte zu ermöglichen. Sofern der Dritte nicht in der
                Lage ist, dem Verkäufer die in diesem Zusammenhang entstehenden
                gerichtlichen oder außergerichtlichen Kosten zu erstatten,
                haftet hierfür der Käufer dem Verkäufer.  <br /> <br />12.11.  <br />Der Verkäufer
                wird die Vorbehaltsware sowie die an ihre Stelle tretenden
                Sachen oder Forderungen freigeben, soweit ihr Wert die Höhe der
                gesicherten Forderungen um mehr als 50 % übersteigt. Die Auswahl
                der danach freizugebenden Gegenstände liegt beim Verkäufer.
                 <br /> <br />12.12.  <br />Der Verkäufer behält sich das Eigentum oder Urheberrecht
                an allen von ihm abgegebenen Angeboten und Kostenvoranschlägen
                sowie dem Käufer zur Verfügung gestellten Zeichnungen,
                Abbildungen, Berechnungen, Prospekten, Katalogen, Modellen,
                Werkzeugen und anderen Unterlagen und Hilfsmitteln vor.  <br /> <br />12.13. <br />
                Der Käufer darf diese Gegenstände ohne ausdrückliche Zustimmung
                des Verkäufers weder als solche noch inhaltlich Dritten
                zugänglich machen, sie bekannt geben, selbst oder durch Dritte
                nutzen oder vervielfältigen.  <br /> <br />12.14.  <br />Er hat auf Verlangen des
                Verkäufers diese Gegenstände vollständig an diesen zurückzugeben
                und eventuell gefertigte Kopien zu vernichten, wenn sie von ihm
                im ordnungsgemäßen Geschäftsgang nicht mehr benötigt werden oder
                wenn Verhandlungen nicht zum Abschluss eines Vertrages führen.
              </p>
              <h4>13. Anwendbares Recht /Sonstiges</h4>
              <p>
                13.1.  <br />Gerichtsstand für alle etwaigen Streitigkeiten aus der
                Geschäftsbeziehung zwischen dem Verkäufer und dem Käufer ist
                nach Wahl des Verkäufers Rudolstadt oder der Sitz des Käufers.
                Für Klagen gegen den Verkäufer ist in diesen Fällen jedoch
                Rudolstadt ausschließlicher Gerichtsstand. Zwingende gesetzliche
                Bestimmungen über ausschließliche Gerichtsstände bleiben von
                dieser Regelung unberührt.  <br /> <br />13.2.  <br />Für die Geschäftsbeziehung mit
                dem Käufer ist ausschließlich das Recht der Bundesrepublik
                Deutschland anwendbar. Die Anwendbarkeit des CISG (UNKaufrecht)
                ist ausgeschlossen.  <br /> <br />13.3.  <br />Soweit der Vertrag oder diese
                Allgemeinen Lieferbedingungen Regelungslücken enthalten, gelten
                zur Ausfüllung dieser Lücken diejenigen rechtlich wirksamen
                Regelungen als vereinbart, welche die Vertragspartner nach den
                wirtschaftlichen Zielsetzungen des Vertrages und dem Zweck
                dieser Allgemeinen Lieferbedingungen vereinbart hätten, wenn sie
                die Regelungslücke gekannt hätten.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
export default {
  name: "Impressum",
  components: {
    Layout,
  },
  methods:{
    
  }
};
</script>

<style></style>
