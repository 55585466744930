<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground">
          <div class="row pt-5 pb-3 justify-content-center" id="t0">
            <div class="col-md-10 title grauHellfeld pt-2 pb-4">
              <div class="row justify-content-center">
                <div class="col-md-12 title text-center">
                  <div class="animate-Title">
                    Leistungen
                  </div>
                </div>
              </div>
            
              
              <div class="row justify-content-center">                

                <div class="col-md-4 text-center bg-white pt-2">
                  <a href="#t1" class="h5AnkerLink">
                    <img
                      src="../assets/DSC_5795.jpg"
                      class="img-fluid mx-auto d-block hoverBig"
                      alt="..."
                    />
                    <h5 class="textbalken">CNC - Fräsen</h5>
                  </a>
                </div>
                <div class="col-md-4 text-center bg-white pt-2 noOpacity">
                  <a href="#t2" class="h5AnkerLink">
                    <img
                      src="../assets/drehen.jpg"
                      class="img-fluid mx-auto d-block hoverBig"
                      alt="..."
                    />
                    <h5 class="textbalken">CNC - Drehen</h5>
                  </a>
                </div>
                <div class="col-md-4 text-center bg-white pt-2  noOpacity">
                  <a href="#t3" class="h5AnkerLink  noOpacity">
                    <img
                      src="../assets/montage.jpg"
                      class="img-fluid mx-auto d-block hoverBig noOpacity"
                      alt="..."
                    />
                    <h5 class="textbalken">Montage</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-3 pt-5 mb-0 m-0 justify-content-center" >
            <div class="col-sm-12 col-md-5 p-3 bg-center titleFull">
              <video controls class="videoClassGross   videosize" >
                      <source  src="../assets/drehen.mp4"
                              type="video/mp4">
                      Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
            <div class="col-sm-12 col-md-5 p-3 bg-center titleFull ">
              <video controls class="videoClassGross videosize" >
                      <source  src="../assets/fraese.mp4"
                              type="video/mp4">
                      Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </div>

          <div class="row pb-5 pt-5 mb-0 m-0 justify-content-center" id="t1">
            <div class="col-sm-12 col-md-10 pdiv5 bg-center title">
              <h1 class="rotSgrau fw-bolder pb-4">CNC-Fräsen</h1>
              <h2>Zerspanung auf hohem Niveau</h2>
              <div class="row">
                <div class="col-md-4 col-sm-12 ps-0">
                  <img
                    src="../assets/DSC_5795.jpg"
                    class="img-fluid d-block pt-3 pb-4 ele3_1"
                    @click="showImage(10)"
                  />
                </div>
                <div class="col-md-8 col-sm-12 p-4">
                  <p>
                    Wir bieten Ihnen umfangreiche Bearbeitungsmöglichkeiten:
                  </p>
                  <ul>
                    <li class="zubRouterLink">
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />
                      Komplettbearbeitung von Frästeilen auf 3-Achs-Bearbeitungszentren
                    </li>
                    
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />
                      Komplettbearbeitung von Frästeilen auf 5-Achs-Bearbeitungszentren
                    </li>
                  </ul>
                  <p>
                    Unsere CNC-Fräszentren zur mechanischen Bearbeitung von
                    Werkstücken sind auf dem aktuellen Stand der Technik. Durch
                    hohe Genauigkeit und Präzision unserer Produkte haben wir
                    uns zu einem verlässlichen und flexiblen Partner entwickelt.
                    Wir können Volumenmodelle aus Ihrem CAD-System übernehmen
                    und für Sie mit modernsten Technologien fertigen. Hier sind auch anspruchsvolle Fräskonturen und Frei formen realisierbar.
                  </p>
                  <p>Fräsbearbeitung in Bearbeitungsgrößen bis zu 600 x 800 mm Verfahrweg</p>
                </div>
              </div>
            </div>
          </div>


          <div class="row pb-5 pt-5 mb-0 m-0 justify-content-center" id="t2">
            <div class="col-sm-10 col-md-10 pdiv5 bg-center title">
              <h1 class="rotSgrau fw-bolder pb-4">CNC-Drehen</h1>

              <h2>Mit hohem Anspruch an Präzision fertigen wir:</h2>
              <div class="row">
                <div class="col-md-4 ps-0">
                  <img
                    src="../assets/abt1.jpg"
                    class="img-fluid d-block pt-3 pb-4 ele3_1"
                    @click="showImage(11)"
                  />
                </div>
                <div class="col-md-6 p-4">
                  <ul>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />
                      Drehteile in Drehdurchmesserbereichen von 4 bis 200 mm
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />
                      Langdrehteile in Duchmesserbereichen von 3 bis 40 mm
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />
                      Sondertechnologien (Gewindewirbel, Tieflochbohren, 6-Kanttaumeln, Nutenstoßen, etc.)
                    </li>
                  </ul>
                  <p>
                    Wir fertigen auf modernen CNC-Drehautomaten und
                    Bearbeitungszentren mit modernster Steuerungstechnologie
                    kleine und mittelgroße Serien.
                  </p>
                  <p>
                    Durch unsere Langdrehtechnologie können wir hochpräzise und
                    technologisch anspruchsvolle Teile in bester Produktqualität
                    fertigen.
                  </p>
                  <p>
                    Bei Komplettbearbeitung, auch mit angetriebenen Werkzeugen,
                    können wir einen größtmöglichsten Bearbeitungsbereich bis
                    Durchmesser 160 mm abdecken.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row pb-5 pt-5 mb-0 m-0 justify-content-center" id="t3">
            <div class="col-sm-10 col-md-10 pdiv5 bg-center title">
              <h1 class="rotSgrau fw-bolder pb-4">Montage</h1>

              <h2>Montage Ihrer Baugruppe bis zum fertigen Produkt</h2>
              <div class="row">
                <div class="col-md-4 ps-0">
                  <img
                    src="../assets/abt6.jpg"
                    class="img-fluid d-block pt-3 pb-4 ele3_1"
                    @click="showImage(12)"
                  />
                </div>
                <div class="col-md-6 p-4">
                  <p>
                    Nicht nur in der Einzelteilfertigung, sondern auch für die
                    komplette Herstellung Ihrer Baugruppe oder Ihres
                    Montageartikels, sind wir ein kompetenter Partner.
                  </p>
                  <p>
                    Nach Ihrer Vorgabe montieren wir die bei uns im Haus
                    gefertigten Teile, mit Komponenten anderen
                    Kooperationspartner, zu einem für sie verkaufsfertigen
                    Produkt.
                  </p>
                  <h4>
                    Nutzen Sie die enorme Zeiteinsparung sowie die logistischen
                    Vorteile durch unser Komplettangebot - Alles aus einer Hand!
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#t0" class="stickyPfeil">
          <i class='fas fa-angle-double-up'></i>
        </a>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
//import carouselWrapProdukte from "../components/carouselWrapProdukte.vue";
import { openModal } from "jenesius-vue-modal";
import ModalsImageDisplay from "../components/ModalsImageDisplay.vue";
//import carouselHome from "../components/carouselHome.vue";

export default {
  name: "Leistungen",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    //carouselWrapProdukte,
    // carouselHome,
  },
  methods: {
    async showImage(Imagenumber) {
      const modal = await openModal(ModalsImageDisplay, {
        Imagenumber: Imagenumber,
      });
      modal.onclose = () => {
        console.log("Close Modal gefunden");

        return true; //Modal will not be closed
      };
    },
  },
  computed: {},
};
</script>

<style scoped></style>
