<template class="bg-vue">
  <div class="row bg-footer justify-content-start border-top border-2 me-0">
   
    <div class="col-sm-12 col-md-12">
      <div class="row">
        <div class="col-sm-10 offset-sm-1 offset-md-2 col-md-3 text-center ps-3">
         <img
                src="../assets/s2dlogo.jpg"
                class="img-fluid pt-3 ps-3 "
                style="width;:80%"
                alt="..."
              />
        </div>
        <div class="col-sm-10 offset-sm-1 col-md-2  offset-md-0 text-center pt-3">
          <p class="text-start ps-2">
            <a
              title="Adresse"
              href="https://goo.gl/maps/Qdh3QA67EtoSSxvv7"
              target="_blank"
            > 
              <i class="fas fa-map-marker-alt"></i
            ></a>
            <a
              href="https://goo.gl/maps/Qdh3QA67EtoSSxvv7"
            >
            Königseer Straße 51,<br />07422 Bad Blankenburg
            </a>
          </p>
          <p class="text-start  text-white ps-2">
            <i class='far fa-copyright'></i>    2024 by HERTWIG CNC
          </p>
        </div>
        <div class="col-md-2 offset-md-0 col-sm-10 offset-sm-1  text-center pt-3">
          <p class="text-start  ps-2">
            <a
              title="Telefon"
              href="tel:036741189160"
              target="_blank"
              rel="noopener"
            >
              <i class="fas fa-phone"></i>
            </a>
            <a href="tel:036741189160">036741 18916-0</a>
          </p>
          <p class="text-start  ps-2">
            <a
              title="Fax"
              href="tel:036741189160"
              target="_blank"
              rel="noopener"
            >
              <i class="fas fa-fax"></i>
            </a>
            <a href="tel:036741189169">036741 18916-9</a>
          </p>
        <p class="text-start ps-2">
            <a
              title="Mail an info@hertwig-cnc.de schreiben"
              href="mailto:info@hertwig-cnc.de"
              target="_blank"
              rel="noopener"
              ><i class="fas fa-envelope  text-white"></i></a
            ><a href="mailto:info@hertwig-cnc.de" class=" text-white"
              >info@hertwig-cnc.de</a
            >
          </p>
          
        </div>
        
        <div class="col-sm-10 offset-sm-1 col-md-2 text-center pt-3">          
          <p class="text-start ps-2">
            <router-link
              to="/Impressum"              
              @click="activesite = 'impressum'"
              >Impressum / ALB</router-link
            >
          </p>
          <p class="text-start ps-2">
            <router-link to="/sitemap">Sitemap</router-link>
          /
            <router-link
              to="/Datenschutz"
              
              @click="activesite = 'datenschutz'"
              >Datenschutz</router-link
            >
          </p>
          <p class="text-start ps-2">
            <a href="https://www.pillarsoft.de"  target="_blank" class=" text-white"
              >Design by PILLARSOFT</a
            >
          </p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {},
  data() {
    return {
      activesite: "",
    };
  },
  created() {},
};
</script>

<style scoped></style>
