<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 kontaktBackground pb-5 pt-5">
          <div class="row  justify-content-center" id="t0">
            <div class="col-md-10 grauHellfeld title pt-2 pb-4">
          
          <div class="row justify-content-center">
                <div class="col-md-12 title text-center">
                  <div class="animate-Title">
                    Sitemap
                  </div>
                </div>
              </div>
          <div class="row justify-content-center">
            <div class="col-md-8  normalerText">
                <ul>     
            <li><a href="https://www.hertwig-cnc.de/">Home</a></li>
            <li><a href="https://hertwig-cnc.de/Leistungen">Leistungen</a></li>
            <li><a href="https://hertwig-cnc.de/Partner">Partner</a></li>
            <li><a href="https://hertwig-cnc.de/Referenzen">Referenzen</a></li>
            <li><a href="https://hertwig-cnc.de/Stellenangebote">Stellenangebote</a></li>
            
            <li><a href="https://hertwig-cnc.de/Kontakt">Kontakt</a></li>
            <li><a href="https://hertwig-cnc.de/Datenschutz">Datenschutz</a></li>
            <li><a href="https://hertwig-cnc.de/Impressum">Impressum</a></li>

                </ul>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Aktuelles",

  data() {
    return {
      aktuelleSite: "ref",
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
