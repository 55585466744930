<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground pb-5">
          <div class="row justify-content-center">
            <div class="col-md-10 grauHellfeld mt-5 mb-5 text-center">
              <div class="animate-Title">Referenzen</div>
            </div>
          </div>

          <div class="row mb-0">
            <div class="col-md-12">
              <div id="ref-1" class="row justify-content-center">
                <div class="col-md-10 title weissSschwarz text-center">
                  <img
                    src="../assets/ref/Petrik.gif"
                    class="img-fluid d-block RefPicFirma"
                    @click="showImage(1)"
                  />
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/petrik2.jpg"
                        class="img-fluid d-block RefPicProduct"
                        @click="showImage(1)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Unser Sortiment umfasst eine Vielzahl an
                        Kombinationsmöglichkeiten von Röntgenröhren im
                        Strahlenschutzgehäuse, die wiederum verschiedene
                        Austrittswinkel, Kühlungsarten,
                        Befestigungsmöglichkeiten und Betriebsspannungen (bis 80
                        kV) haben können. Neben verschiedenen Baugrößen können
                        wir Ihnen optional eine Vernickelung des Gehäuses
                        anbieten.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="ref-1" class="row ele3_1 justify-content-center pt-5">
                <div class="col-md-10 title weissSschwarz text-center">
                  <img
                    src="../assets/ref/Dias.png"
                    class="img-fluid d-block RefPicFirma"
                    @click="showImage(2)"
                  />
                  <div class="row pt-4">
                    <div class="col-md-5 pt-1 ps-0">
                      <img
                        src="../assets/ref/Dias2.jpg"
                        class="img-fluid d-block RefPicProduct"
                        @click="showImage(2)"
                      />
                    </div>
                    <div class="col-md-7">
                      <p>
                        Die DIAS Infrared GmbH mit Sitz in Dresden entwickelt
                        und fertigt hochwertige Wärmebildkameras,
                        Infrarot-Linienkameras, Pyrometer, schwarze Strahler und
                        Infrarotsensoren für industrielle Temperaturmessungen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { openModal } from "jenesius-vue-modal";
import ModalsImageDisplay from "../components/ModalsImageDisplay.vue";

export default {
  name: "AktuellesRef",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
    };
  },
  components: {
    Layout,
  },
  methods: {
    async showImage(Imagenumber) {
      const modal = await openModal(ModalsImageDisplay, {
        Imagenumber: Imagenumber,
      });
      modal.onclose = () => {
        console.log("Close Modal gefunden");

        return true; //Modal will not be closed
      };
    },
  },
  computed: {},
  created() {
    this.show = true;
  },
};
</script>

<style scoped></style>
