<template>
  <div class="card text-center text-vue" style="width: 1002px; height: 900px">
    <div class="card-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <img
              src="../assets/ref/petrik2.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 1"
            />
            <img
              src="../assets/ref/Dias2.jpg"
              class="img-fluid"
              alt="..."
              v-if="Imagenumber == 2"
            />
            <img
              src="../assets/zerspanung.jpg"
              class="img-fluid d-block pt-3 pb-4"
              v-if="Imagenumber == 10"
            />
            <img
              src="../assets/drehen.jpg"
              class="img-fluid d-block pt-3 pb-4"
              v-if="Imagenumber == 11"
            />
            <img
              src="../assets/montage.jpg"
              class="img-fluid d-block pt-3 pb-4"
              v-if="Imagenumber == 12"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button type="button" class="btn btn-danger" @click="close()">
        Schließen
      </button>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer"></div>
  </div>
</template>
<script>
import { closeModal } from "jenesius-vue-modal";
export default {
  name: "ModalsImageDisplay",
  props: { Imagenumber: Number },
  methods: {
    close() {
      closeModal();
    },
  },
};
</script>
<style scoped>
img {
  /* img width responds to wrapper width*/
  max-width: 100%;
  height: 90%;
}
</style>
