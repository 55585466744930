<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground pb-5 pt-5">
          <div class="row  justify-content-center" id="t0">
            <div class="col-md-10 grauHellfeld title pt-2 pb-4">

              <div class="row justify-content-center">
                <div class="col-md-12 title text-center">
                  <div class="animate-Title">
                    Kontakt
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
            <div class="col-md-10 title grauHellfeld">
              

              <div class="ps-4">
                Gerne erwarten wir Ihre Nachricht an uns. Wir werden diese so
                schnell wie möglich beantworten.
              </div>
              <div class="container py-4">
                <!-- Bootstrap 5 starter form -->
                <form id="contactForm">
                  <!-- Name input -->
                  <div class="mb-3">
                    <label class="form-label" for="name">Name*</label>
                    <input
                      class="form-control"
                      id="name"
                      type="text"
                      placeholder="Name"
                      v-model="mail.name"
                      v-bind:class="background.name"
                    />
                  </div>

                  <!-- Email address input -->
                  <div class="mb-3">
                    <label class="form-label" for="emailAddress"
                      >Email Adressen*</label
                    >
                    <input
                      class="form-control"
                      id="emailAddress"
                      placeholder="Email Adresse"
                      v-model="mail.email"
                      v-bind:class="background.email"
                    />
                  </div>

                  <!-- Message input -->
                  <div class="mb-3">
                    <label class="form-label" for="message">Mitteilung*</label>
                    <textarea
                      class="form-control"
                      id="message"
                      type="text"
                      placeholder="Bitte senden Sie uns Ihre unverbindliche Anfrage"
                      style="height: 10rem"
                      v-model="mail.message"
                      v-bind:class="background.message"
                    ></textarea>
                  </div>

                  <!-- Form submissions success message -->
                  <div class="d-none" id="submitSuccessMessage">
                    <div class="text-center mb-3">Formular wurde gesendet!</div>
                  </div>

                  <!-- Form submissions error message -->
                  <div class="d-none" id="submitErrorMessage">
                    <div class="text-center text-danger mb-3">
                      Fehler beim senden des Formulars!<br />
                      Sie können uns auch unter der Rufnummer: 036741 18916-0
                      erreichen
                    </div>
                  </div>

                  <div class="col-md-8 mb-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="andere"
                      v-model="mail.datenschutz"
                      v-bind:class="background.datenschutz"
                    />
                    <label class="form-check-label" for="andere"
                      >&nbsp; Ich habe die
                      <a class="zitate" href="/Datenschutz"
                        >Datenschutzerklärung</a
                      >
                      zur Kenntnis genommen.
                    </label>
                    <label class="form-check-label inputerror"> </label>
                  </div>

                  <!-- Form submit button -->
                  <div class="d-grid">
                    <button
                      class="btn btn-outline-danger btn-lg bg-main"
                      @click="sendMail()"
                    >
                      Senden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

              
            </div>
          </div>

          
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import axios from "axios";

export default {
  name: "Kontakt",

  data() {
    return {
      newSite: null,
      mail: {
        name: "",
        email: "",
        message: "",
        datenschutz: false,
        gesendet: "",
      },
      background: {
        name: "",
        email: "",
        message: "",
        datenschutz: "",
      },
    };
  },
  components: {
    Layout,
  },
  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }
      if (this.mail.message == "") {
        this.background.message = "border border-2 border-danger";
        re = false;
      } else {
        this.background.message = "border border-2 border-success";
      }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "info@hertwig-cnc.de",
          //empfaenger: "joerg@pillar.de",
          Name: this.mail.name,
          Email: this.mail.email,
          Grund: "Kontaktaufnahme",
          Text: this.mail.message,
          Datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post(
            "https://pillar.de/mailrelay/post_attachment.php",
            payload,
            axiosConfig
          )
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name = "";
            background.name = "";
            mail.email = "";
            background.email = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>
