<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground">
          <div class="row justify-content-center">
            <div class="col-md-10 title grauHellfeld mt-5 mb-5">
              <div class="row justify-content-center">                
                <div class="col-md-12 title text-center">
                  <div class="animate-Title">
                    Kooperationspartner
                  </div>
                </div>
              </div>

              <div class="row pt-2 mb-5 mt-0 justify-content-center">
                <div class="col-md-3 p-1">
                  <img
                    src="../assets/p3.jpg" 
                    class="img-fluid d-block imageToBig"
                  />
                </div>
                <div class="col-md-3 p-1">
                  <img
                    src="../assets/p1.jpg"
                    class="img-fluid d-block imageToBig"
                  />
                </div>
                <div class="col-md-3 p-1">
                  <img
                    src="../assets/p2.jpg"
                    class="img-fluid d-block imageToBig"
                  />
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-12 title weissSschwarz">
                  <p>
                    Um Ihnen stets die neuesten Technologien und Trends anbieten
                    zu können, arbeiten wir mit Partnern aus den folgenden
                    Bereichen zusammen, die mit dem gleichen Qualitätsanspruch
                    wie wir arbeiten:
                  </p>
                  <ul>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Laserbearbeitung (z.B. Laserzuschnitte)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Blechbearbeitung (Abkanten, Biegen)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Schweißen (WIG, MAG, Laserschweißen)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Härten (Vakuumhärten)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Oberflächenveredelung (Eloxieren, Passivieren,
                      Vernickeln, Verzinken, Pulverbeschichtung, Vergolden, Lackieren, KTL-Beschichtungen, usw.)
                    </li>
                    <li>
                      <img
                        src="../assets/Kreuz.png"
                        class="Kreuzpunkt"
                        alt="..."
                      />Teilekennzeichnung (Laserbeschriftung, Gravieren usw.)
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { openModal } from "jenesius-vue-modal";
import ModalsImageDisplay from "../components/ModalsImageDisplay.vue";

export default {
  name: "AktuellesRef",

  data() {
    return {
      aktuelleSite: "bewe",
      show: false,
    };
  },
  components: {
    Layout,
  },
  methods: {
    async showImage(Imagenumber) {
      const modal = await openModal(ModalsImageDisplay, {
        Imagenumber: Imagenumber,
      });
      modal.onclose = () => {
        console.log("Close Modal gefunden");

        return true; //Modal will not be closed
      };
    },
  },
  computed: {},
  created() {
    this.show = true;
  },
};
</script>

<style scoped></style>
