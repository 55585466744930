<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground pb-5">   

          <div class="row justify-content-center">
            <div class="col-md-8">
              <h1 class="rotSgrauBig fw-bolder pt-5 pb-5 " style="text-align: center">
                Datenschutzerklärung
              </h1>
            </div>
          </div>

          <div class="row pt-2 mb-0 mt-0  justify-content-center">
            <div class="col-md-8 title grauHellfeld">
              <div>
                <div class="normalerText">
                  <h3 class="title">Geltungsbereich</h3>
                  <p>
                    Diese Datenschutzerklärung klärt Nutzer über die Art, den
                    Umfang und Zwecke der Erhebung und Verwendung
                    personenbezogener Daten durch den verantwortlichen Anbieter
                    HERTWIG CNC Präzisions - Fertigungstechnik GmbH auf dieser Website (im folgenden
                    “Anbieter”) auf.
                  </p>
                  <p>
                    Die rechtlichen Grundlagen des Datenschutzes finden sich im
                    Bundesdatenschutzgesetz (BDSG), dem Telemediengesetz (TMG),
                    dem Telekommunikationsgesetz (TKG), der
                    Datenschutz-Grundverordnung der EU (DSGVO) und dem Gesetz
                    zur Erhöhung der Sicherheit informationstechnischer Systeme
                    (ITSiG).
                  </p>
                  <h3 class="title">SSL-Verschlüsselung</h3>
                  <p>
                    Website-Betreiber sind nach § 13 TMG dazu verpflichtet „[…],
                    soweit dies technisch möglich und wirtschaftliche zumutbar
                    ist, im Rahmen ihrer jeweiligen Verantwortlichkeit für
                    geschäftsmäßige angebotene Telemedien durch technische und
                    organisatorische Vorkehrungen sicherzustellen, dass […] die
                    genutzten techischen Einrichtungen […] gegen Verletzung des
                    Schutzes personenbezogener Daten […] gesichert sind“. Die
                    Übertragung von Daten von und zu dieser Webseite sind mit
                    einem SSL-Zertifkat geschützt, aus Gründen der
                    Kompatibilität wird jedoch auch eine unverschlüsselte
                    Verbindung angeboten. Sollte Ihr Browser nicht automatisch
                    die verschlüsselte Verbindung nutzen, so können Sie unter
                    <a href="https://hertwig-cnc.de">diesem Link</a> manuell die
                    Verschlüsselung aktivieren.
                  </p>
                  <p>
                    Einige Client-Programme führen eine Gültigkeitsprüfung für
                    Zertifikate durch. Das ermöglicht der Certificate Authority
                    (dem austellenden Unternehmen) nachzuvollziehen, welche
                    Domains von Ihrer IP aus besucht wurden. Derartige
                    Mechanismen liegen weit außerhalb des Einflussbereiches des
                    Anbieters.
                  </p>
                  <h3 class="title">Zugriffsdaten / Server-Logfiles</h3>
                  <p>
                    Der Anbieter (beziehungsweise sein Webspace-Provider) erhebt
                    Daten über jeden Zugriff auf das Angebot (so genannte
                    Serverlogfiles). Zu den Zugriffsdaten gehören:
                  </p>
                  <ul>
                    <li>Name der abgerufenen Webseite / Datei</li>
                    <li>Datum und Uhrzeit des Abrufs</li>
                    <li>übertragene Datenmenge</li>
                    <li>Meldung über erfolgreichen Abruf</li>
                    <li>Browsertyp nebst Version</li>
                    <li>das Betriebssystem des Nutzers</li>
                    <li>Referrer URL (die zuvor besuchte Seite)</li>
                    <li>IP-Adresse und der anfragende Provider</li>
                  </ul>
                  <p>
                    Der Anbieter verwendet die Protokolldaten nur für
                    statistische Auswertungen zum Zweck des Betriebs, der
                    Sicherheit und der Optimierung des Angebotes. Der
                    Anbieterbehält sich jedoch vor, die Protokolldaten
                    nachträglich zu überprüfen, wenn aufgrund konkreter
                    Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen
                    Nutzung besteht. Durch die Logfiles gewinnen wir zum anderen
                    Erkenntnisse darüber, wie unser Dienst genutzt wird. So
                    erfahren wir zum Beispiel, welche Seiten besonders beliebt
                    sind. Die Ergebnisse dieser Analyse liegen am Ende in
                    statistischer Form vor und sind anonym. Wir verwenden
                    IP-Adressen nicht, um Nutzer zu identifizieren. IP-Adressen
                    speichern wir über einen Zeitraum von maximal vierzehn
                    Tagen.
                  </p>
                  <p>
                    Wir setzen auf den Logfiles basierende
                    Einbruchserkennungssysteme (IDS) ein, welche bei mutmaßlich
                    illegaler oder unsachgemäßer Nutzung des Angebotes die
                    IP-Adresse und damit verbundene Daten über diesen Zeitraum
                    hinaus speichern können.
                  </p>
                  <h3 class="title">Umgang mit personenbezogenen Daten</h3>
                  <p>
                    Personenbezogene Daten sind Informationen, mit deren Hilfe
                    eine Person bestimmbar ist, also Angaben, die zurück zu
                    einer Person verfolgt werden können. Dazu gehören die IP,
                    der Name, die E-Mail-Adresse oder die Telefonnummer, aber
                    auch Daten über Vorlieben, Hobbies, Mitgliedschaften oder
                    welche Webseiten von jemandem angesehen wurden zählen zu
                    personenbezogenen Daten.
                  </p>
                  <p>
                    Personenbezogene Daten werden von dem Anbieter nur dann
                    erhoben, genutzt und weiter gegeben, wenn der Nutzer dem
                    nicht widersprochen hat (OptOut). Dritte Parteien werden
                    nachfolgend einzeln genannt. Sie können unser Online-Angebot
                    grundsätzlich ohne Offenlegung Ihrer Identität nutzen. Es
                    unterliegt Ihrer freien Entscheidung, ob Sie Ihre Identität
                    für personalisierte Dienste preisgeben. Ihre Angaben
                    speichern wir auf besonders geschützten Servern in
                    Deutschland und Frankreich. Der Zugriff auf die Daten ist
                    nur wenigen besonders befugten und unterwiesenen Personen
                    möglich, die mit der technischen, kaufmännischen oder
                    redaktionellen Betreuung der Server oder des Angebotes
                    befasst sind. Nicht anonymisierte Daten werden nur so lange
                    gespeichert, wie dies für eine eventuelle Kontaktaufnahme
                    nötig ist oder wir durch gesetzliche Aufbewahrungspflichten,
                    etwa zu steuerlichen oder buchhalterischen Zwecken, dazu
                    verpflichtet sind.
                  </p>
                  <h3 class="title">Verwendung von Webfonts</h3>
                  <p>
                    Auf diesen Internetseiten werden externe Schriften, Google
                    Fonts verwendet. Google Fonts ist ein Dienst der Google Inc.
                    ("Google"). Die Einbindung dieser Web Fonts erfolgt durch
                    einen Serveraufruf, in der Regel ein Server von Google in
                    den USA. Hierdurch wird an den Server übermittelt, welche
                    unserer Internetseiten Sie besucht haben. Auch wird die
                    IP-Adresse des Browsers des Endgerätes des Besuchers dieser
                    Internetseiten von Google gespeichert. Nähere Informationen
                    finden Sie in den Datenschutzhinweisen von Google, die Sie
                    hier abrufen können:<br />
                    <a
                      href="https://www.google.com/fonts#AboutPlace:about"
                      rel="nofollow"
                      target="_blank"
                      >www.google.com/fonts#AboutPlace:about</a
                    ><br />
                    <a
                      href="https://www.google.com/policies/privacy/"
                      rel="nofollow"
                      target="_blank"
                      >www.google.com/policies/privacy/</a
                    >
                  </p>
                  <h3 class="title">Verwendung von Font Awesome</h3>
                  <p>
                    Auf diesen Internetseiten wird Font Awesome verwendet,
                    welches über den Anbieter BootstrapCDN eingebunden ist. Die
                    Einbindung dieser Schriftart erfolgt durch einen
                    Serveraufruf, in der Regel ein Server in den USA. Hierdurch
                    wird an den Server übermittelt, welche unserer
                    Internetseiten Sie besucht haben. Auch wird die IP-Adresse
                    des Browsers des Endgerätes des Besuchers dieser
                    Internetseiten von BootstrapCDN gespeichert. Nähere
                    Informationen finden Sie in den Datenschutzhinweisen von
                    BootstrapCDN, die Sie hier abrufen können:<br />
                    <a
                      href="http://fontawesome.io/"
                      rel="nofollow"
                      target="_blank"
                      >fontawesome.io/</a
                    ><br />
                    <a
                      href="https://www.bootstrapcdn.com/privacy-policy/"
                      rel="nofollow"
                      target="_blank"
                      >www.bootstrapcdn.com/privacy-policy/</a
                    >
                  </p>
                  <h3 class="title">
                    Widerruf, Änderungen, Berichtigungen und Aktualisierungen
                  </h3>
                  <p>
                    Der Nutzer hat das Recht, auf Antrag unentgeltlich Auskunft
                    zu erhalten über die personenbezogenen Daten, die über ihn
                    gespeichert wurden. Zusätzlich hat der Nutzer das Recht auf
                    Berichtigung unrichtiger Daten, Sperrung und Löschung seiner
                    personenbezogenen Daten, soweit dem keine gesetzliche
                    Aufbewahrungspflicht entgegensteht.
                  </p>
                  <p>
                    Die Auskunft erfolgt auf Wunsch auf elektronischem Wege oder
                    schriftlich zur Abholung am Unternehmenssitz. Eine Zusendung
                    auf postalischem Wege ist gegen Kostenersatz möglich. Eine
                    unentgeltliche Auskunft ist einmal pro Kalenderjahr möglich,
                    jede weitere Auskunft kann gegen ein Entgelt erworben
                    werden, sofern keine nachweisbaren Hinweise auf
                    unzulässigerweise oder unrichtig gespeicherte Daten
                    existieren.
                  </p>
                  <p>
                    Ihr Recht auf Widerruf und Korrektur können Sie gegenüber
                    folgender Anschrift geltend machen:
                  </p>
                  <p>
                    HERTWIG CNC Präzisions - Fertigungstechnik GmbH<br />
                    Königseer Straße 51<br />
                    07422 Bad Blankenburg<br />
                    <a href="mailto:info@hertwig-cnc.de" class="email"
                      >info@hertwig-cnc.de</a
                    >
                  </p>
                  <h3 class="title">
                    Recht auf Einschränkung der Verarbeitung,
                    Datenübertragbarkeit und Beschwerderecht bei einer
                    Aufsichtsbehörde
                  </h3>
                  <p>
                    Der Nutzer hat das Recht auf die Einschränkung der
                    Verarbeitung der sich auf ihn beziehenden personengebundenen
                    Daten. Sofern anwendbar, hat der registrierte Besucher
                    Anspruch auf die Herausgabe der von ihm bereitgestellten
                    Daten in offenen standardisierten Datenformaten in
                    komulierter Form, die es ihm ermöglichen, diese Daten zu
                    Konkurrenzprodukten zu übertragen. Weiterhin hat der
                    betroffene Nutzer des Angebotes bei mutmaßlichen Verstößen
                    gegen die gesetzliche Grundlage das Recht, jederzeit
                    Beschwerde bei der zuständigen Aufsichtsbehörde
                    einzureichen.
                  </p>
                  <h3 class="title">
                    Widerspruchsrecht gegen die Verarbeitung
                  </h3>
                  <p>
                    <strong>
                      Gemäß Art. 21 DSGVO hat jede betroffene Person das Recht
                      auf Widerspruch gegen die Verarbeitung der auf sie
                      bezogenen perönlichen Daten, sofern die jeweiligen
                      gesetzlichen Vorraussetzungen vorliegen.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
export default {
  name: "Impressum",
  components: {
    Layout,
  },
};
</script>

<style></style>
