<template>
  <carousel 
    :items-to-show="3" 
    :wrap-around="true" 
    :autoplay="3000" 
    class="noHandy">
    <slide v-for="slide in slides" :key="slide">
      <router-link to="/Leistungen"
        ><img
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"
          v-bind:height=(windowWidth/6)
          v-bind:class="imgCH" />
        <span class="spanCH" 
          :style="`font-size: ${(windowWidth/80)}px`">
          {{slide.text}}
          </span
      ></router-link>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
  <carousel 
    :items-to-show="1" 
    :wrap-around="true" 
    :autoplay="3000" 
    class="noDesktop">
    <slide v-for="slide in slides" :key="slide">
      <router-link to="/Leistungen"
        ><img
          :src="getImgUrl(slide.bild)"
          v-bind:alt="pic"
          v-bind:height="250"
          v-bind:class="imgCH" />
        <span class="spanCH">
          {{slide.text}} 
          </span
      ></router-link>
    </slide>    
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "../eigen_css/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
  data() {
    const slides = [
      {bild:"nb1",text:"CNC-Langdrehautomat"},
      {bild:"nb2",text:"CNC-Drehautomat mit Automatisierung"},
      {bild:"nb3",text:"CNC- Drehmachine mit Stangenzuführung"},
      {bild:"nb4",text:"neues Fertigwarenlagersystem"},
      {bild:"nb5",text:"Qualitätskontrolle"},
      {bild:"nb6",text:"Finish"},
      {bild:"nb7",text:"Entfettung"},
      {bild:"nb8",text:"Büro"},
      {bild:"nb9",text:"Tageslichtdach"},
      
      
    ];
    return {
      slides,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeUnmouned() { 
    window.removeEventListener('resize', this.onResize); 
  },
};
</script>
