<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground">
          <router-link
            to="/Stellenangebote#ausbildung"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Stellenangebote' ? 'nav-active' : 'nav-deactiv',
            ]"
          >
            <img
              src="../assets/WirBildenAus2.png"
              class="stickyAusbildung noHandy"
              alt="..."
            />
          </router-link>

          <!--    <router-link
            to="/Stellenangebote#qa"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Stellenangebote' ? 'nav-active' : 'nav-deactiv',
            ]"
          >
            <div class="linksEinfahren stickyStellen stellenAktuell">
              <div class="weissSschwarz text-center p-5 stickyStellenDetail">
                Mitarbeiter/in für die Qualitätssicherung gesucht
              </div>
              <div class="stinkyStellenKurztext">QA-MA GESUCHT</div>
            </div>
          </router-link>
-->
          <div
            class="row justify-content-center pt-5 pb-5"
            style="height: 600px"
            ref="Kopf"
            id="Kopf"
            v-bind:class="{ vonLinks: checkKopf() }"
          >
            <div class="col-md-6 col-12 title weissSschwarz text-center">
              <div class="text-center">
                <img src="../assets/Hertwig_Logo.jpg" class="img-fluid pt-3" alt="..." />
                <h1 class="blau mb-0 pt-4 p-0 fw-bold text-center ele">
                  Präzision und Flexibilität <br />in der Zerspanung <br />für Ihr
                  Produkt!
                </h1>
                <h3 class="rot fw-bold ele pt-4" style="text-align: center">
                  CNC-Fräsen, CNC-Drehen & Montage
                </h3>
              </div>
            </div>
          </div>


          <div
            class="row justify-content-center pt-2 pb-2"
            ref="Erfahrung"
            id="Erfahrung"
            v-bind:class="{ vonLinks: checkErfahrung() }"
          >
            <div class="col-md-8 title grauHellfeld text-center">
              <h1 class="h2Rot pb-4">
                Nutzen Sie unsere Erfahrung - die Ihnen Zeit und Geld spart.
              </h1>

              <p>
                Wir bieten Ihnen einen Komplett-Service in der Realisierung Ihrer Teile
                von der Fertigung bis hin zur Montage. Ihre Zufriedenheit ist für uns das
                Maß aller Dinge – daher wenden wir uns mit Präzision, Zuverlässigkeit,
                Flexibilität und einem hohen Maß an Verantwortungsbewusstsein Ihrem
                Auftrag zu.
              </p>
              <p>
                Jeden Auftrag sehen wir als Herausforderung und bearbeiten ihn individuell
                nach Ihren Wünschen und Vorgaben.
              </p>

              <router-link to="/Kontakt" style="text-decoration: none">
                <h3 class="h2Rot pt-4">
                  Senden Sie uns Ihre Nachricht
                  <i class="fas fa-long-arrow-alt-right"></i></h3
              ></router-link>
            </div>
          </div>

          <div class="row pt-3 pb-3 justify-content-center" id="t0">
            <div class="col-md-10 title weissSschwarz pt-2 pb-4">
              <div class="row justify-content-center">
                <div class="col-md-12 title text-center">
                 
                  <h3 class="pt-5" >Der neue Standort in Bad Blankenburg ist fertig.</h3>
                  
                  <h4 class="pt-3">
                    Seit 1.März sind wir an unserem neuen Standort für Sie da. 
                  </h4>
                    <h4>In den neuen Räumen am Stadtrand von Bad Blankenburg können wir jetzt in gewohnt hoher Qualität Ihre Wünsche erfüllen und die Aufgaben der Zukunft meistern.
                  </h4>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-12">
                  <carouselHomeNeubau></carouselHomeNeubau>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12 title text-center">
                  <h4>
                    Unsere neue Adresse :
                  </h4>
                  <h3 class="pt-4">Königseer Straße 51</h3>
                  <h3>Bad Blankenburg</h3>
                 
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row pt-3 pb-3 justify-content-center" id="t0">
            <div class="col-md-10 title weissSschwarz pt-2 pb-4">
              <div class="row justify-content-center">
                <div class="col-md-12 title text-center">
                  <h2 class="h2Rot">Produktion und Fertigung</h2>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-12">
                  <carouselHome></carouselHome>
                </div>
              </div>
            </div>
          </div> -->

          <div
            class="row mb-0 mt-0 justify-content-center pb-5"
            ref="Qualitätsanspruch"
            id="Qualitätsanspruch"
            v-bind:class="{ vonLinks: checkQualitaetsanspruch() }"
          >
            <div class="col-md-8 title grauHellfeld text-center">
              <h2 class="h2Rot mb-5">Unser Qualitätsanspruch</h2>
              <p>
                Entsprechend der Qualitätsmanagement-Norm DIN EN ISO 9001 verfolgt die
                Firma HERTWIG CNC Präzisions - Fertigungstechnik GmbH wichtige Grundsätze,
                die einen hohen Qualitätsstandard bei all unseren Produkten aus der
                Metallverarbeitung und CNC-Fertigung gewährleisten.
              </p>
              <p>
                Wir arbeiten nach dem Motto: Nur zufriedene Kunden möchten langfristig mit
                uns zusammen arbeiten.
              </p>
              <p>
                <img
                  src="../assets/tuevISO9001.jpg"
                  class="img-fluid p-3"
                  alt="..."
                  style="max-height: 200px"
                />
              </p>

              <p>Bitte laden sie HIER unsere Zertifikat herunter</p>
              <a
                href="http://www.hertwig-cnc.de/downloads/zertifikat_deu.pdf"
                target="_blank"
              >
                Zertifikat Deutsch
              </a>
              <a
                class="ps-5"
                href="http://www.hertwig-cnc.de/downloads/zertifikat_eng.pdf"
                target="_blank"
              >
                Zertifikat Englisch
              </a>

              <p class="pt-4">
                Wir haben die Elemente der DIN EN ISO 9001 um Bausteine der DIN EN ISO
                13485 erweitert, somit können wir auch den hohen Anspruch der
                Medizinproduktehersteller gerecht zu werden.
              </p>
            </div>
          </div>

          <div
            class="row pb-5 justify-content-center"
            ref="Fertigungstechnik"
            id="Fertigungstechnik"
            v-bind:class="{ vonLinks: checkFertigungstechnik() }"
          >
            <div class="col-md-8 title weissSschwarz text-center">
              <h2 class="h2Rot pb-4">Langjährige Erfahrung für Ihren Erfolg</h2>

              <p>
                HERTWIG CNC Präzisions - Fertigungstechnik GmbH wurde 2011 aus dem
                Mechanikbereich der MESELA GmbH von Lars Hertwig gegründet.
              </p>
              <p>
                Wir sind unserer Philosophie treu geblieben, unseren Kunden neben präzisen
                und effizient ausgeführten Aufträgen eine eingehende fachliche Beratung zu
                bieten. Getragen und gelebt wird dieses Konzept von unseren engagierten
                und motivierten Mitarbeitern. Durch ständige Weiterbildung arbeiten wir
                dabei nach den neuesten Erkenntnissen und mit den modernsten Technologien.
              </p>
              <p>
                Wir sind für unsere Kunden Partner und verläßlicher Problemlöser mit einem
                breiten Spektrum und einer Vielfalt an Möglichkeiten.
              </p>
            </div>
          </div>

          <div
            class="row pb-5 justify-content-center"
            ref="Leistungen"
            id="Leistungen"
            v-bind:class="{ vonLinks: checkLeistungen() }"
          >
            <div class="col-md-8 title grauHellfeld text-center">
              <h2 class="rot pb-4">Unsere Leistungen</h2>

              <p>
                Unser Portfolio umfasst die Bearbeitung verschiedener Edelstähle, Titan,
                Nickel, Sonderlegierungen, Aluminium, Bronze, Messing, Kupfer bis hin zu
                den verschiedenen Kunststoffen auf modernen CNC-Maschinen.
              </p>
              <p>
                Als Zulieferer von Mess- und Sensortechnik sowie Medizintechnik haben wir
                uns aufgrund der hohen Anforderungen zu einem leistungsfähigen
                CNC-Spezialbetrieb entwickelt. Für die Herstellung von Prototypen und
                Serienartikeln nutzen wir moderne CNC-Technik.
              </p>
              <p class="pb-5">
                Um einen reibungslosen Ablauf der Aufträge zu gewährleisten, verfügen wir
                über ein umfangreiches Materiallager. Dies versetzt uns in die Lage Ihre
                Aufträge in kurzer Zeit zu realisieren.
              </p>
            </div>
          </div>
          <div
            class="row pb-5 justify-content-center"
            ref="Banner"
            id="Banner"
            v-bind:class="{ vonLinks: checkLeistungen() }"
          >
            <div class="col-md-8 titleFull weissSschwarz text-center">
              <h2 class="rot pb-4 pt-4">Produktbeispiele</h2>
              <carouselWrapProdukte></carouselWrapProdukte>
            </div>
          </div>
          <div
            class="row pb-5 justify-content-center"
            ref="Unternehmensstandort"
            id="Unternehmensstandort"
            v-bind:class="{ vonLinks: checkLeistungen() }"
          >
            <div class="col-md-8 titleFull grauHellfeld text-center">
              <h2 class="rot pb-4">Unternehmensstandort</h2>
              <h4 class="rot ">HERTWIG CNC Präzisions - Fertigungstechnik GmbH</h4>
              <h4 class="rot ">Königseer Straße 51</h4>
              <h4 class="rot pb-4">07422 Bad Blankenburg</h4>
              <img
              src="../assets/neubauKarte.jpg"
              class="img-fluid p-3"
              alt="..."
              style="max-width: 90%"
            />
              <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.9282197301186!2d11.274456151171334!3d50.684157378278115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a3fb8635c2335f%3A0x556f9e9217bb6d4a!2sHermann-Petersilge-Stra%C3%9Fe%203%2C%2007422%20Bad%20Blankenburg!5e0!3m2!1sde!2sde!4v1660655207114!5m2!1sde!2sde"
                width="99%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="border border-2 border-dark iframeSize"
              ></iframe> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
<link rel="stylesheet" href="https://unpkg.com/vue-agile/dist/VueAgile.css" />;
import Layout from "@/layouts/Layout";
import carouselWrapProdukte from "../components/carouselWrapProdukte.vue";
//import carouselHome from "../components/carouselHome.vue";
import carouselHomeNeubau from "../components/carouselHomeNeubau.vue";
import { useElementVisibility } from "@vueuse/core";
import { ref } from "vue";

export default {
  name: "Home",
  Kopfstart: 0,
  Erfahrungstart: 0,
  Qualitätsanspruchstart: 0,
  Fertigungstechnikstart: 0,
  Leistungenstart: 0,
  Unternehmensstandortstart: 0,
  setup() {
    const Kopf = ref(null);
    const KopfIsVisible = useElementVisibility(Kopf);
    const Erfahrung = ref(null);
    const ErfahrungIsVisible = useElementVisibility(Erfahrung);
    const Qualitätsanspruch = ref(null);
    const QualitätsanspruchIsVisible = useElementVisibility(Qualitätsanspruch);
    const Fertigungstechnik = ref(null);
    const FertigungstechnikIsVisible = useElementVisibility(Fertigungstechnik);
    const Leistungen = ref(null);
    const LeistungenIsVisible = useElementVisibility(Leistungen);
    const Unternehmensstandort = ref(null);
    const UnternehmensstandortIsVisible = useElementVisibility(Unternehmensstandort);
    const Banner = ref(null);
    const BannerIsVisible = useElementVisibility(Banner);

    return {
      Kopf,
      KopfIsVisible,
      Erfahrung,
      ErfahrungIsVisible,
      Qualitätsanspruch,
      QualitätsanspruchIsVisible,
      Fertigungstechnik,
      FertigungstechnikIsVisible,
      Leistungen,
      LeistungenIsVisible,
      Unternehmensstandort,
      UnternehmensstandortIsVisible,
      Banner,
      BannerIsVisible,
    };
  },

  data() {
    return {
      newSite: null,
      zuBInnen: "zubInnen",
      zuBInnenOver: "zubInnenOver",
    };
  },
  components: {
    Layout,
    carouselWrapProdukte,
    //carouselHome,
    carouselHomeNeubau,
  },
  methods: {
    addClass(e) {
      console.log("innen");
      e.target.classList.remove("zubInnen");
      e.target.classList.add("zubInnenOver");
    },
    removeClass(e) {
      console.log("außen");
      e.target.classList.remove("zubInnenOver");
      e.target.classList.add("zubInnen");
    },
    checkKopf() {
      if (this.Kopfstart == 0) {
        this.Kopfstart = 1;
        return this.KopfIsVisible;
      }
      // return this.KopfIsVisible;
      return true;
    },
    checkErfahrung() {
      if (this.Erfahrungstart == 0) {
        this.Erfahrungstart = 1;
        return this.ErfahrungIsVisible;
      }
      // return this.KopfIsVisible;
      return true;
    },
    checkQualitaetsanspruch() {
      if (this.Qualitätsanspruchstart == 0) {
        this.Qualitätsanspruchstart = 1;
        return this.QualitätsanspruchIsVisible;
      }
      // return this.KopfIsVisible;
      return true;
    },
    checkFertigungstechnik() {
      if (this.Fertigungstechnikstart == 0) {
        this.Fertigungstechnikstart = 1;
        return this.FertigungstechnikIsVisible;
      }
      // return this.KopfIsVisible;
      return true;
    },
    checkLeistungen() {
      if (this.Leistungenstart == 0) {
        this.Leistungenstart = 1;
        return this.LeistungenIsVisible;
      }
      // return this.KopfIsVisible;
      return true;
    },
    checkUnternehmensstandort() {
      if (this.Unternehmensstandortstart == 0) {
        this.Unternehmensstandortstart = 1;
        return this.KopfIsVisible;
      }
      // return this.KopfIsVisible;
      return true;
    },
  },
  computed: {},
};
</script>

<style scoped></style>
