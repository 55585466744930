<template class="bg-vue">
  <div
    class="row bg-navbar justify-content-center me-0 titleHeight"
    style="padding-bottom: 7px; padding-top: 17px"
  >
    <div class="col-sm-6 col-md-2" v-if="activeScreen != '/'">
      <img src="../assets/s2dlogo.jpg" class="HeaderLogo" alt="..." />
    </div>
    <div class="col-sm-12 col-md-9 pb-2">
      <ul class="nav nav-pills nav-item">
        <li class="me-1">
          <router-link
            to="/"
            class="nav-link"
            v-bind:class="[activeScreen == '/' ? 'nav-active' : 'nav-deactiv']"
          >
            <img
              src="../assets/Kreuz.png"
              class="Kreuzpunkt"
              alt="..."
            />Start</router-link
          >
        </li>
        <li class="me-1">
          <router-link
            to="/Leistungen"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Leistungen' ? 'nav-active' : 'nav-deactiv',
            ]"
            ><img
              src="../assets/Kreuz.png"
              class="Kreuzpunkt"
              alt="..."
            />Leistungen</router-link
          >
        </li>
        <li class="me-1">
          <router-link
            to="/Partner"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Partner' ? 'nav-active' : 'nav-deactiv',
            ]"
            ><img
              src="../assets/Kreuz.png"
              class="Kreuzpunkt"
              alt="..."
            />Partner</router-link
          >
        </li>
        <li class="me-1">
          <router-link
            to="/Referenzen"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Referenzen' ? 'nav-active' : 'nav-deactiv',
            ]"
            ><img
              src="../assets/Kreuz.png"
              class="Kreuzpunkt"
              alt="..."
            />Referenzen</router-link
          >
        </li>
        <li class="me-1">
          <router-link
            to="/Stellenangebote"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Stellenangebote' ? 'nav-active' : 'nav-deactiv',
            ]"
            ><img
              src="../assets/Kreuz.png"
              class="Kreuzpunkt"
              alt="..."
            />Stellenangebote</router-link
          >
        </li>
        <li class="me-4">
          <router-link
            to="/Kontakt"
            class="nav-link"
            v-bind:class="[
              activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv',
            ]"
            ><img
              src="../assets/Kreuz.png"
              class="Kreuzpunkt"
              alt="..."
            />Kontakt</router-link
          >
        </li>
      </ul>
    </div>

    <div class="col-sm-6 col-md-1 img-tuev" style="position: relative">
      <a
        href="http://www.hertwig-cnc.de/downloads/zertifikat_deu.pdf"
        target="_blank"
      >
        <img
          src="../assets/tuevISO9001.jpg"
          height="70"
          alt="..."
          style="display: block"
          class="HeaderLogo"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 2000);
    },
  },
  data() {
    return {
      visi: false,
    };
  },
  created() {},
};
</script>

<style scoped></style>
